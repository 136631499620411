import { addToCartConfig, PUB_SUB_EVENTS, publish } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { routes } from '@/scripts/setup'
import {
	closestOptional,
	getAttributeOrThrow,
	qsOptional,
	qsRequired,
	toggleIsEmpty,
	toggleLoading,
} from '@/scripts/functions'
import { type CartNotification } from '@/scripts/cart/cart-notification'
import { type CartDrawer } from '@/scripts/cart/cart-drawer'
import { QuickAddModal } from '@/scripts/product/quick-add'
import { type NotifyMe } from '@/scripts/content/modal'

export class ProductForm extends UcoastEl {
	static htmlSelector = 'product-form'
	form: HTMLFormElement
	cart: CartNotification | CartDrawer
	submitButton: HTMLButtonElement
	spinner: HTMLElement
	errorMessageWrapper: HTMLElement
	errorMessage: HTMLElement
	error: boolean
	notifyMe?: NotifyMe

	constructor() {
		super()
		this.form = this.initForm()
		this.cart = qsOptional('cart-notification') || qsRequired('cart-drawer')
		this.submitButton = qsRequired('[type="submit"]', this)
		if (document.querySelector('cart-drawer') && this.submitButton)
			this.submitButton.setAttribute('aria-haspopup', 'dialog')
		this.spinner = qsRequired('[data-uc-spinner]', this)
		this.errorMessageWrapper = qsRequired('[data-uc-error-message-wrapper]', this)
		this.errorMessage = qsRequired('[data-uc-error-message]', this.errorMessageWrapper)
		this.error = false
		this.notifyMe = qsOptional('notify-me')
	}

	initForm(): HTMLFormElement {
		const form = qsRequired<HTMLFormElement>('form', this)
		const id = qsRequired<HTMLInputElement>('[name=id]', form)
		id.disabled = false
		form.addEventListener('submit', this.onSubmitHandler.bind(this))
		return form
	}

	onSubmitHandler(evt: SubmitEvent): void {
		evt.preventDefault()
		if (this.submitButton.getAttribute('aria-disabled') === 'true') return
		const inventoryStatus = getAttributeOrThrow('data-uc-inventory-status', this.submitButton)
		if (this.notifyMe && inventoryStatus === 'OutOfStock') {
			this.notifyMe.initialize(this.submitButton)
			return
		}
		this.handleErrorMessage()

		this.submitButton.setAttribute('aria-disabled', '')
		toggleLoading(this.submitButton, true)
		this.spinner.classList.remove('hidden')

		const formData = new FormData(this.form)

		if (this.cart) {
			formData.append(
				'sections',
				this.cart
					.getSectionsToRender()
					.map((section) => section.id)
					.join(',')
			)
			formData.append('sections_url', window.location.pathname)
			const documentActiveElement = document.activeElement as HTMLElement | undefined
			if (documentActiveElement) {
				this.cart.setActiveElement(documentActiveElement)
			}
		}
		const config = addToCartConfig(formData)

		fetch(`${routes.cart_add_url}`, config)
			.then((response) => {
				return response
			})
			.then((response) => response.json())
			.then((response) => {
				if (response.status) {
					this.handleErrorMessage(response.description)

					const soldOutMessage = this.submitButton.querySelector('.sold-out-message')
					if (!soldOutMessage) return
					this.submitButton.setAttribute('aria-disabled', '')
					const text = this.submitButton.querySelector('span')
					if (text) text.classList.add('hidden')
					soldOutMessage.classList.remove('hidden')
					this.error = true
					return
				} else if (!this.cart) {
					window.location.href = routes.cart_url
					return
				}

				if (!this.error) publish(PUB_SUB_EVENTS.cartUpdate, { source: 'product-form' })
				this.error = false
				const quickAddModal = closestOptional<QuickAddModal>(this, 'quick-add-modal')
				if (quickAddModal) {
					document.body.addEventListener(
						'modalClosed',
						() => {
							setTimeout(() => {
								this.cart.renderContents(response)
							})
						},
						{ once: true }
					)
					quickAddModal.hide()
				} else {
					this.cart.renderContents(response)
				}
			})
			.catch((e) => {
				console.error(e)
			})
			.finally(() => {
				toggleLoading(this.submitButton, false)
				if (this.cart && this.cart.hasAttribute('data-uc-is-empty'))
					toggleIsEmpty(this.cart, false)
				if (!this.error) this.submitButton.removeAttribute('aria-disabled')
				this.spinner.classList.add('hidden')
			})
	}

	handleErrorMessage(errorMessage: string | false = false): void {
		this.errorMessageWrapper =
			this.errorMessageWrapper || this.querySelector('[data-uc-error-message-wrapper]')
		if (!this.errorMessageWrapper) return
		this.errorMessage =
			this.errorMessage || this.errorMessageWrapper.querySelector('[data-uc-error-message]')
		this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage)
		if (errorMessage && this.errorMessage) {
			this.errorMessage.textContent = errorMessage
		}
	}
}
