import { Modal } from '@/scripts/content/modal'
import {
	elementPropertyRequired,
	qsaOptional,
	qsOptional,
	qsRequired,
	qsRequiredFromDocument,
	replaceAll,
	toggleLoading,
} from '@/scripts/functions'
import { type uCoastWindow } from '@/scripts/setup'
import { VariantSelects } from '@/scripts/product/product-options'
import { ProductInfo } from '@/scripts/product/product-info'

declare let window: uCoastWindow

export class QuickAddModal extends Modal {
	static override htmlSelector = 'quick-add-modal'
	modalContent: HTMLElement
	productElement?: HTMLElement
	constructor() {
		super()
		this.modalContent = qsRequired('[id^="QuickAddInfo-"]', this)
	}

	override hide(preventFocus = false) {
		this.modalContent.innerHTML = ''

		if (preventFocus) this.openedBy = undefined
		super.hide()
	}

	override show(opener: HTMLButtonElement) {
		opener.setAttribute('aria-disabled', 'true')
		toggleLoading(opener, true)
		const spinner = qsRequired('[data-uc-spinner]', opener)
		spinner.classList.remove('hidden')
		const productUrl = opener.getAttribute('data-product-url') as string | undefined
		if (!productUrl) throw `Product Url Undefined, QuickAddModal`

		fetch(productUrl)
			.then((response) => response.text())
			.then((responseText) => {
				const responseHTML = new DOMParser().parseFromString(responseText, 'text/html')
				this.productElement = qsRequiredFromDocument(
					'section[id^="MainProduct-"]',
					responseHTML
				)
				this.preventDuplicatedIDs()
				this.removeDOMElements()
				this.setInnerHTML(this.modalContent, this.productElement.innerHTML)

				if (window.Shopify && window.Shopify.PaymentButton) {
					window.Shopify.PaymentButton.init()
				}

				//if (window.ProductModel) window.ProductModel.loadShopifyXR()
				// re enable for productModel

				this.removeGalleryListSemantic()
				this.updateImageSizes()
				this.preventVariantURLSwitching()
				super.show(opener)
			})
			.finally(() => {
				opener.removeAttribute('aria-disabled')
				toggleLoading(opener, false)
				spinner.classList.add('hidden')
			})
	}

	setInnerHTML(element: HTMLElement, html: string) {
		element.innerHTML = html

		// Reinjects the script tags to allow execution. By default, scripts are disabled when using element.innerHTML.
		element.querySelectorAll('script').forEach((oldScriptTag: HTMLScriptElement) => {
			try {
				const scriptParent = elementPropertyRequired(oldScriptTag, 'parentNode')
				const newScriptTag = document.createElement('script')
				Array.from(oldScriptTag.attributes).forEach((attribute) => {
					newScriptTag.setAttribute(attribute.name, attribute.value)
				})
				newScriptTag.appendChild(document.createTextNode(oldScriptTag.innerHTML))

				scriptParent.replaceChild(newScriptTag, oldScriptTag)
			} catch (error) {
				console.log('Error replacing script tags into innerHTML')
			}
		})
	}

	preventVariantURLSwitching() {
		const variantPicker = this.modalContent.querySelector('variant-radios,variant-selects')
		if (!variantPicker) return

		variantPicker.setAttribute('data-update-url', 'false')
	}

	removeDOMElements() {
		if (!this.productElement) throw 'this.productElement not found'

		const productModal = this.productElement.querySelector('product-modal')
		if (productModal) productModal.remove()

		const modalDialog = this.productElement.querySelectorAll('modal-dialog')
		if (modalDialog) modalDialog.forEach((modal) => modal.remove())
	}

	preventDuplicatedIDs() {
		if (!this.productElement) throw 'this.productElement not found'
		const sectionId = this.productElement.dataset.section
		if (!sectionId) throw 'sectionId not found'
		this.productElement.innerHTML = replaceAll(
			this.productElement.innerHTML,
			sectionId,
			`quickadd-${sectionId}`
		)
		const optionEls = qsaOptional<VariantSelects | ProductInfo>(
			'variant-selects, variant-radios, product-info',
			this.productElement
		)
		if (!optionEls) return
		optionEls.forEach((element) => {
			element.dataset.originalSection = sectionId
		})
	}

	removeGalleryListSemantic() {
		const galleryList = qsOptional('[id^="Slider-Gallery"]', this.modalContent)
		if (!galleryList) return
		galleryList.setAttribute('role', 'presentation')
		galleryList
			.querySelectorAll('[id^="Slide-"]')
			.forEach((li) => li.setAttribute('role', 'presentation'))
	}

	updateImageSizes() {
		const product = qsRequired('[data-uc-product]', this.modalContent)
		const desktopColumns = product.classList.contains('product--columns')
		if (!desktopColumns) return

		const mediaImages = product.querySelectorAll('.product__media img')
		if (!mediaImages.length) return

		let mediaImageSizes =
			'(min-width: 1000px) 715px, (min-width: 750px) calc((100vw - 11.5rem) / 2), calc(100vw - 4rem)'

		if (product.classList.contains('product--medium')) {
			mediaImageSizes = mediaImageSizes.replace('715px', '605px')
		} else if (product.classList.contains('product--small')) {
			mediaImageSizes = mediaImageSizes.replace('715px', '495px')
		}

		mediaImages.forEach((img) => img.setAttribute('sizes', mediaImageSizes))
	}
}
