import { SearchForm } from '@/scripts/forms/search-form'
import { qsaRequired, qsOptional, qsRequired, qsRequiredFromDocument } from '@/scripts/functions'
import { type uCoastWindow } from '@/scripts/setup'

declare let window: uCoastWindow

export class PredictiveSearch extends SearchForm {
	static override htmlSelector = 'predictive-search'
	isOpen: boolean
	isMobile: boolean
	limit: number
	cachedResults: Record<string, string>
	predictiveSearchResults: HTMLElement
	allPredictiveSearchInstances: NodeListOf<PredictiveSearch>
	abortController: AbortController
	searchTerm: string
	newSearchTerm?: string
	statusElement?: HTMLElement
	loadingText?: string
	resultsMaxHeight?: number
	constructor() {
		super()
		this.isMobile = this.dataset.breakpoint === 'tablet'
		this.limit = this.isMobile ? 5 : 6
		this.cachedResults = {}
		this.predictiveSearchResults = qsRequired('[data-predictive-search]', this)
		this.allPredictiveSearchInstances = qsaRequired('predictive-search')
		this.isOpen = false
		this.abortController = new AbortController()
		this.searchTerm = ''

		this.setupEventListeners()
	}

	setupEventListeners() {
		const form = this.input.form
		if (!form) throw 'no input form, PredictiveSearch'
		form.addEventListener('submit', this.onFormSubmit.bind(this))
		this.input.addEventListener('focus', this.onFocus.bind(this))
		this.addEventListener('focusout', this.onFocusOut.bind(this))
		this.addEventListener('keyup', this.onKeyup.bind(this))
		this.addEventListener('keydown', this.onKeydown.bind(this))
	}

	getQuery() {
		return this.input.value.trim()
	}

	override onChange() {
		super.onChange()
		this.newSearchTerm = this.getQuery()

		// Update the term asap, don't wait for the predictive search query to finish loading
		this.updateSearchForTerm(this.searchTerm, this.newSearchTerm)

		this.searchTerm = this.newSearchTerm

		if (!this.searchTerm.length) {
			this.close(true)
			return
		}

		this.getSearchResults(this.searchTerm)
	}

	onFormSubmit(event: SubmitEvent) {
		event.preventDefault()
		if (!this.getQuery().length || this.querySelector('[aria-selected="true"] a'))
			event.preventDefault()
	}

	override onFormReset(event: Event) {
		super.onFormReset(event)
		if (super.shouldResetForm()) {
			this.searchTerm = ''
			this.abortController.abort()
			this.abortController = new AbortController()
			this.closeResults(true)
		}
	}

	onFocus() {
		const currentSearchTerm = this.getQuery()

		if (!currentSearchTerm.length) return

		if (this.searchTerm !== currentSearchTerm) {
			// Search term was changed from other search input, treat it as a user change
			this.onChange()
		} else if (this.getAttribute('results') === 'true') {
			this.open()
		} else {
			this.getSearchResults(this.searchTerm)
		}
	}

	onFocusOut() {
		setTimeout(() => {
			if (!this.contains(document.activeElement)) {
				this.close()
			}
		})
	}

	onKeyup(event: KeyboardEvent) {
		if (!this.getQuery().length) {
			this.close(true)
		}
		event.preventDefault()

		switch (event.code) {
			case 'ArrowUp':
				this.switchOption('up')
				break
			case 'ArrowDown':
				this.switchOption('down')
				break
			case 'Enter':
				this.selectOption()
				break
		}
	}

	onKeydown(event: KeyboardEvent) {
		// Prevent the cursor from moving in the input when using the up and down arrow keys
		if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
			event.preventDefault()
		}
	}

	updateSearchForTerm(previousTerm: string, newTerm: string) {
		const searchForTextElement = qsOptional('[data-predictive-search-search-for-text]', this)
		const currentButtonText = searchForTextElement?.innerText
		if (currentButtonText) {
			const matchingEls = currentButtonText.match(new RegExp(previousTerm, 'g'))
			if (matchingEls && matchingEls.length > 1) {
				// The new term matches part of the button text and not just the search term, do not replace to avoid mistakes
				return
			}
			searchForTextElement.innerText = currentButtonText.replace(previousTerm, newTerm)
		}
	}

	switchOption(direction: 'up' | 'down') {
		if (!this.getAttribute('open')) return

		const moveUp = direction === 'up'
		const selectedElement = this.querySelector('[aria-selected="true"]')

		// Filter out hidden elements (duplicated page and article resources) thanks
		// to this https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
		const allVisibleElements = Array.from(
			qsaRequired('li, button.predictive-search__item', this)
		).filter((element) => element.offsetParent !== null)
		let activeElementIndex = 0

		if (moveUp && !selectedElement) return

		let selectedElementIndex = -1
		let i = 0

		while (selectedElementIndex === -1 && i <= allVisibleElements.length) {
			if (allVisibleElements[i] === selectedElement) {
				selectedElementIndex = i
			}
			i++
		}

		if (this.statusElement) {
			this.statusElement.textContent = ''
		}

		if (!moveUp && selectedElement) {
			activeElementIndex =
				selectedElementIndex === allVisibleElements.length - 1
					? 0
					: selectedElementIndex + 1
		} else if (moveUp) {
			activeElementIndex =
				selectedElementIndex === 0
					? allVisibleElements.length - 1
					: selectedElementIndex - 1
		}

		if (activeElementIndex === selectedElementIndex) return

		const activeElement = allVisibleElements[activeElementIndex]

		activeElement.setAttribute('aria-selected', 'true')
		if (selectedElement) selectedElement.setAttribute('aria-selected', 'false')

		this.input.setAttribute('aria-activedescendant', activeElement.id)
	}

	selectOption() {
		const selectedOption = qsRequired(
			'[aria-selected="true"] a, button[aria-selected="true"]',
			this
		)

		if (selectedOption) selectedOption.click()
	}

	getSearchResults(searchTerm: string) {
		const queryKey = searchTerm.replace(' ', '-').toLowerCase()
		this.setLiveRegionLoadingState()

		if (this.cachedResults[queryKey]) {
			this.renderSearchResults(this.cachedResults[queryKey])
			return
		}

		fetch(
			`${window.routes.predictive_search_url}?q=${encodeURIComponent(
				searchTerm
			)}&section_id=predictive-search&resources[limit_scope]=each&resources[limit]=${
				this.limit
			}`,
			{
				signal: this.abortController.signal,
			}
		)
			.then((response) => {
				if (!response.ok) {
					const error = new Error(`${response.status}`)
					this.close()
					throw error
				}

				// moved search clear from here
				if (
					!this.searchTerm ||
					(this.newSearchTerm && !this.newSearchTerm.startsWith(this.searchTerm))
				) {
					// Remove the results when they are no longer relevant for the new search term
					// so they don't show up when the dropdown opens again
					this.querySelector('#predictive-search-results-groups-wrapper')?.remove()
				}
				return response.text()
			})
			.then((text) => {
				const resultsMarkup = qsRequiredFromDocument(
					'#shopify-section-predictive-search',
					new DOMParser().parseFromString(text, 'text/html')
				).innerHTML
				// Save bandwidth keeping the cache in all instances synced
				this.allPredictiveSearchInstances.forEach((predictiveSearchInstance) => {
					predictiveSearchInstance.cachedResults[queryKey] = resultsMarkup
				})
				this.renderSearchResults(resultsMarkup)
			})
			.catch((error) => {
				if (error?.code === 20) {
					// Code 20 means the call was aborted
					return
				}
				console.log('caught 09')
				this.close()
				throw error
			})
	}

	setLiveRegionLoadingState() {
		this.statusElement =
			this.statusElement || qsRequired('[data-uc-predictive-search-status]', this)
		this.loadingText = this.loadingText || this.getAttribute('data-loading-text') || ''

		this.setLiveRegionText(this.loadingText)
		this.setAttribute('loading', 'true')
	}

	setLiveRegionText(statusText: string) {
		if (!this.statusElement) return
		this.statusElement.setAttribute('aria-hidden', 'false')
		this.statusElement.textContent = statusText

		setTimeout(() => {
			if (!this.statusElement) return
			this.statusElement.setAttribute('aria-hidden', 'true')
		}, 1000)
	}

	renderSearchResults(resultsMarkup: string) {
		this.predictiveSearchResults.innerHTML = resultsMarkup
		this.setAttribute('results', 'true')

		this.setLiveRegionResults()
		this.open()
	}

	setLiveRegionResults() {
		this.removeAttribute('loading')
		const textContent =
			qsRequired('[data-predictive-search-live-region-count-value]', this)?.textContent ?? ''
		this.setLiveRegionText(textContent)
	}

	getResultsMaxHeight() {
		this.resultsMaxHeight = this.isMobile
			? window.innerHeight - qsRequired('form', this).getBoundingClientRect().bottom
			: window.innerHeight - qsRequired('.section-header').getBoundingClientRect().bottom
		return this.resultsMaxHeight
	}

	open() {
		this.predictiveSearchResults.style.maxHeight = this.resultsMaxHeight
			? `${this.resultsMaxHeight}`
			: `${this.getResultsMaxHeight()}px`
		this.setAttribute('open', 'true')
		this.input.setAttribute('aria-expanded', 'true')
		this.isOpen = true
	}

	close(clearSearchTerm = false) {
		this.closeResults(clearSearchTerm)
		this.isOpen = false
	}

	clearSearchTerm() {
		this.input.value = ''
		this.removeAttribute('results')
	}

	closeResults(clearSearchTerm = false) {
		if (clearSearchTerm) {
			this.clearSearchTerm()
		}
		const selected = this.querySelector('[aria-selected="true"]')

		if (selected) selected.setAttribute('aria-selected', 'false')

		this.input.setAttribute('aria-activedescendant', '')
		this.removeAttribute('loading')
		this.removeAttribute('open')
		this.input.setAttribute('aria-expanded', 'false')
		this.resultsMaxHeight = undefined
		this.predictiveSearchResults.removeAttribute('style')
	}
}
