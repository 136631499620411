import { qsOptional, qsRequired, qsRequiredFromDocument } from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { mediaLoader } from '@/scripts/mediaLoader'
import { getRecentlyViewedProducts } from '@/scripts/setup'
import { SlideshowComponent } from '@/scripts/media/sliders'

export class ProductRecommendations extends UcoastEl {
	static htmlSelector = 'product-recommendations'
	showRelatedButton: HTMLButtonElement
	showRecentButton: HTMLButtonElement
	relatedSlider: SlideshowComponent
	recentSlider: SlideshowComponent
	constructor() {
		super()
		this.showRelatedButton = qsRequired('[data-uc-show-related]', this)
		this.showRecentButton = qsRequired('[data-uc-show-recent]', this)
		this.relatedSlider = qsRequired('[data-uc-related]', this)
		this.recentSlider = qsRequired('[data-uc-recent]', this)
	}

	async createRelatedSlideshow(recentlyViewedProducts: string[]) {
		let slideshowComponent = document.createElement('div')
		slideshowComponent.className = 'slider-mobile-gutter'

		let innerDiv = document.createElement('div')
		innerDiv.id = 'Slider-recent'
		innerDiv.className =
			'contains-card contains-card--product complementary-slider grid grid--2-col grid--3-col-desktop slider slider--everywhere'
		innerDiv.setAttribute('role', 'list')
		innerDiv.setAttribute('aria-label', "{{ 'general.slider.name' | t }}")

		const currentUrl = window.location.href.split('?')[0]

		for (let i = 0; i < recentlyViewedProducts.length; i++) {
			if (recentlyViewedProducts[i] === currentUrl) continue
			const url = `${recentlyViewedProducts[i]}?section_id=recent-products-slide`

			fetch(url)
				.then((response) => response.text())
				.then((responseText) => {
					const html = new DOMParser().parseFromString(responseText, 'text/html')
					const el = qsRequiredFromDocument('[data-uc-slideshow-slide]', html)
					innerDiv.appendChild(el)
				})
				.catch((e) => {
					console.error(e)
				})
		}

		slideshowComponent.appendChild(innerDiv)

		return slideshowComponent
	}

	showRecent() {
		this.relatedSlider.classList.add('visually-hidden')
		this.recentSlider.classList.remove('visually-hidden')
		this.recentSlider.focus()
		this.showRecentButton.classList.add('active')
		this.showRelatedButton.classList.remove('active')
	}

	showRelated() {
		this.relatedSlider.classList.remove('visually-hidden')
		this.recentSlider.classList.add('visually-hidden')
		this.relatedSlider.focus()
		this.showRecentButton.classList.remove('active')
		this.showRelatedButton.classList.add('active')
	}

	override async connectedCallback() {
		super.connectedCallback()
		const url = this.dataset.url
		if (!url) throw `url not found, ProductReccomendations`

		const recentlyViewedProducts = getRecentlyViewedProducts()
		if (recentlyViewedProducts.length) {
			this.showRecentButton.classList.remove('hidden')
			this.showRecentButton.addEventListener('click', () => {
				this.showRecent()
			})
			this.showRelatedButton.addEventListener('click', () => {
				this.showRelated()
			})
		}

		const recentlyViewedSlider = this.createRelatedSlideshow(recentlyViewedProducts)

		fetch(url)
			.then((response) => response.text())
			.then((text) => {
				const html = document.createElement('div')
				html.innerHTML = text
				const recommendations = qsOptional('product-recommendations', html)

				if (recommendations instanceof ProductRecommendations) {
					const relatedSlider = qsRequired('[data-uc-related]', recommendations)
					this.relatedSlider.innerHTML = relatedSlider.innerHTML
				} else {
					console.error(
						'returned something unexpected from product recommendations',
						recommendations
					)
				}

				if (
					!this.querySelector('slideshow-component') &&
					this.classList.contains('complementary-products')
				) {
					this.remove()
				}

				if (html.querySelector('.grid__item')) {
					this.classList.add('product-recommendations--loaded')
				}
				mediaLoader()
			})
			.catch((e) => {
				console.error(e)
			})

		await recentlyViewedSlider.then((slider) => {
			this.recentSlider.appendChild(slider)
		})
	}
}
